<template>
  <div class="header-container">
    <div class="left-section">
      <div class="heading">{{ headerTitle }}</div>
      <div class="subheading">
        {{ headerSubTitle }} <img :src="imgSrcs.InfoIcon" alt="Info icon" />
      </div>
    </div>
    <div class="right-section">
      <button
        class="group logout-btn hover:!bg-brand-red group-hover:text-brand-white"
        @click.prevent="logoutBtnClickEvent"
      >
        <img :src="imgSrcs.LogoutIcon" class="group-hover:hidden" alt="logout icon" />
        <img
          :src="imgSrcs.LogoutHoveredIcon"
          class="hidden group-hover:block"
          alt="logout icon"
        />
        Log out
      </button>
    </div>
  </div>
</template>

<script>
import LogoutIcon from "@assets/icons/logout.svg";
import LogoutHoveredIcon from "@assets/icons/logout-hovered.svg";
import InfoIcon from "@assets/icons/info-icon.svg";
import storage from "@modules/storage/localStorage";
export default {
  name: "DashboardHeader",
  created() {},
  computed: {
    headerTitle() {
      return this.headerTitles[this.$route.name] ?? "header";
    },
    headerSubTitle() {
      return this.headerSubTitles[this.$route.name] ?? "subheader";
    },
  },
  data() {
    return {
      headerTitles: {
        dashboard: "Dashboard",
        account: "My Account",
      },
      headerSubTitles: {
        dashboard: "Review your daily and all-time activity",
        account: "View and manage your ClassPass account details, subscription, etc.",
      },
      imgSrcs: {
        LogoutIcon,
        LogoutHoveredIcon,
        InfoIcon,
      },
    };
  },
  props: {},
  methods: {
    logoutBtnClickEvent() {
      storage.remove("jwt_token");
      this.$store.commit("clearUser");
      this.$router.push({ name: "signin" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/extra-default.scss";
@tailwind components;

@layer components {
  .header-container {
    @apply h-20 border-b-2 border-brand-border-gray flex justify-between items-center;

    .left-section {
      .heading {
        @apply text-2xl text-brand-white;
      }
      .subheading {
        @apply text-sm text-brand-gray flex gap-2;
      }
    }
    .right-section {
      @apply w-[15%] min-w-[150px];
      .logout-btn {
        @apply custom-btn flex gap-2 w-full  bg-brand-blue-100 border-brand-blue-100;
      }
    }
  }
}
</style>
