import { createRouter, createWebHistory } from "vue-router";
import { toast } from "vue3-toastify";
import DashboardBaseView from "../views/DashboardBaseView.vue";
import store from "@dashboard/store";
import storage from "@modules/storage/localStorage";
import getTokenInfo from "@modules/getTokenInfo";
import EmptyLayout from "@dashboard/views/EmptyLayout.vue";

const routes = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: "dashboard" },
  },
  {
    path: "/",
    component: EmptyLayout,
    beforeEnter: async (to, from, next) => {
      const token = to.query.token;
      const trial = to.query.trial;
      const type = to.query.type ?? "monthly";
      if (token) {
        await storage.set("jwt_token", token);
        if (trial) {
          subscribe(token, type, next);
        } else {
          next({ path: to.path, query: {} });
        }
      } else {
        next();
      }
    },
    children: [
      {
        path: "",
        component: DashboardBaseView,
        meta: { auth: true },
        children: [
          {
            name: "dashboard",
            path: "",
            component: () =>
              import("@dashboard/views/DashboardBaseView/DashboardView.vue"),
          },
          {
            name: "account",
            path: "account",
            component: () =>
              import("@dashboard/views/DashboardBaseView/AccountView.vue"),
          },
          {
            name: "download",
            path: "download",
            component: () =>
              import("@dashboard/views/DashboardBaseView/DownloadView.vue"),
          },
          {
            name: "tools",
            path: "tools",
            component: () =>
              import("@dashboard/views/DashboardBaseView/ToolsView.vue"),
          },
        ],
      },
      {
        path: "",
        component: EmptyLayout,
        children: [
          {
            path: "",
            component: () => import("@dashboard/views/AuthView.vue"),
            meta: { auth: false },
            children: [
              {
                name: "signin",
                path: "signin",
                component: () =>
                  import("@dashboard/views/AuthView/SignInView.vue"),
              },
              {
                name: "signup",
                path: "signup",
                component: () =>
                  import("@dashboard/views/AuthView/SignUpView.vue"),
              },
            ],
          },
          {
            path: "forgot-password",
            name: "forgot-password",
            meta: { auth: undefined },
            component: () =>
              import("@dashboard/views/AuthView/ForgotPasswordView.vue"),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  const requiresAuth = to.matched.some((record) => record.meta.auth);
  const requiresUnauth = to.matched.some(
    (record) => record.meta.auth === false
  );

  if (!isAuthenticated() && !to.query.token) {
    await getTokenInfo()
      .then((userData) => {
        if (userData) {
          store.commit("setUser", userData);
        }
      })
      .catch(() => {
        storage.clear();
        store.commit("clearUser");
      });
  }

  if (to.query.trial === "true") {
    if (to.name !== "signup" && to.name !== "signin") {
      return next({ name: "signin", query: { trial: "true" } });
    }
    if (isAuthenticated()) {
      const user = store.state.user;
      if (user.isSubscribed) {
        next({ name: "dashboard" });
        setTimeout(() => {
          toast.error("You are already subscribed");
        }, 500);
        return;
      } else {
        const type = to.query.type ?? "monthly";
        subscribe(await storage.get("jwt_token"), type, next);
      }
    }
  }

  if (requiresAuth && !isAuthenticated()) {
    next({ name: "signin" });
  } else if (requiresUnauth && isAuthenticated()) {
    next({ name: "dashboard" });
  } else {
    next();
  }
});

export default router;
function subscribe(token, type, next) {
  fetch("/api/user/subscribe?type=" + type, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.text())
    .then((url) => {
      if (url == "User already has a subscription.") {
        next({ name: "dashboard" });
        setTimeout(() => {
          toast.error("You are already subscribed");
        }, 500);
        return;
      }
      window.location.href = url;
    });
}
