<template>
  <component
    :is="data.isExternal ? 'a' : 'RouterLink'"
    :to="data.isExternal ? undefined : { name: data.route }"
    :href="data.isExternal ? data.route : undefined"
    :target="data.isExternal ? '_blank' : undefined"
    :rel="data.isExternal ? 'noopener noreferrer' : undefined"
  >
    <div
      ref="container"
      :class="{ 'list-item': true, active: isActive, 'justify-between': true }"
    >
      <div class="h-full flex items-center gap-2 transition-none px-3">
        <SVGIcon
          :src="data.icon"
          :normalColor="isActive ? '#748FFD' : undefined"
          hoverColor="#748FFD"
          :noHoverRef="false"
          :hoverRef="elRefs.container"
          class="cursor-pointer h-fit w-[12px] list-icon"
        />

        {{ data.title }}
      </div>
      <SVGIcon
        style="width: 7px"
        :src="
          require('!!raw-loader!@/assets/dashboard-page/selected-ellipse.svg').default
        "
        :class="{ hidden: !isActive }"
      />
    </div>
  </component>
</template>

<script>
import SVGIcon from "../../SVG.vue";

import { RouterLink } from "vue-router";
export default {
  name: "ListComponent",
  components: {
    SVGIcon,
  },
  data() {
    return {
      elRefs: {
        container: null,
      },
    };
  },

  created() {},
  computed: {
    isActive() {
      return this.$route.name == this.data.route;
    },
  },
  updated() {
    this.$nextTick(() => {
      if (this.$refs.container) {
        this.elRefs.container = this.$refs.container;
        console.log("Container successfully assigned:", this.elRefs.container);
      } else {
        console.error("Failed to find container ref.");
      }
    });
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.container) {
        this.elRefs.container = this.$refs.container;
        console.log("Container successfully assigned:", this.elRefs.container);
      } else {
        console.error("Failed to find container ref.");
      }
    });
  },
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@tailwind components;
@layer components {
  .list-item {
    @apply cursor-pointer h-10 rounded-md flex items-center text-sm font-medium gap-2 transition-none;
    svg {
      @apply w-[13.5px] transition-colors;
    }
    &:not(.active) {
      @apply text-brand-gray;
      .selected-icon {
        @apply hidden;
      }
    }
    &:hover {
      @apply text-brand-main;
    }
    &.active {
      @apply text-brand-white bg-brand-blue-200;
    }
  }
}
</style>

<style></style>
